<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M423.724,370.759H17.655C7.904,370.759,0,362.855,0,353.103V88.276
	c0-9.751,7.904-17.655,17.655-17.655h406.069c9.751,0,17.655,7.904,17.655,17.655v264.828
	C441.379,362.855,433.475,370.759,423.724,370.759z"
    />
    <g>
      <path
        style="fill:#FFC850;"
        d="M406.069,211.862c-68.255,0-123.586,55.331-123.586,123.586c0,12.279,1.852,24.115,5.186,35.31
		h136.055c9.75,0,17.655-7.905,17.655-17.655V217.048C430.184,213.714,418.348,211.862,406.069,211.862z"
      />
      <path
        style="fill:#FFC850;"
        d="M132.414,203.034H61.793c-4.875,0-8.828-3.953-8.828-8.828v-52.966c0-4.875,3.953-8.828,8.828-8.828
		h70.621c4.875,0,8.828,3.953,8.828,8.828v52.966C141.241,199.082,137.289,203.034,132.414,203.034z"
      />
    </g>
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M123.586,275.862H52.966c-6.094,0-11.034-4.94-11.034-11.034c0-6.094,4.94-11.034,11.034-11.034
		h70.621c6.094,0,11.034,4.94,11.034,11.034C134.621,270.922,129.681,275.862,123.586,275.862z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M335.448,328.828H52.966c-6.094,0-11.034-4.94-11.034-11.034c0-6.094,4.94-11.034,11.034-11.034
		h282.483c6.094,0,11.034,4.94,11.034,11.034C346.483,323.887,341.543,328.828,335.448,328.828z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M229.517,275.862h-70.621c-6.094,0-11.034-4.94-11.034-11.034c0-6.094,4.94-11.034,11.034-11.034
		h70.621c6.094,0,11.034,4.94,11.034,11.034C240.552,270.922,235.612,275.862,229.517,275.862z"
      />
    </g>
    <path
      style="fill:#E1A546;"
      d="M114.759,158.897H97.103v-26.483H79.448v70.621h17.655v-26.483h17.655
	c4.875,0,8.828-3.953,8.828-8.828S119.634,158.897,114.759,158.897z"
    />
    <circle style="fill:#FF507D;" cx="295.724" cy="163.31" r="39.724" />
    <circle style="fill:#FFC850;" cx="348.69" cy="163.31" r="39.724" />
    <path
      style="fill:#FF8C66;"
      d="M308.966,163.31c0,11.719,5.17,22.144,13.241,29.415c8.072-7.271,13.241-17.696,13.241-29.415
	s-5.17-22.144-13.241-29.415C314.135,141.166,308.966,151.592,308.966,163.31z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M335.448,275.862h-70.621c-6.094,0-11.034-4.94-11.034-11.034c0-6.094,4.94-11.034,11.034-11.034
	h70.621c6.094,0,11.034,4.94,11.034,11.034C346.483,270.922,341.543,275.862,335.448,275.862z"
    />
    <g>
      <path
        style="fill:#E4EAF8;"
        d="M297.811,275.862h37.638c6.094,0,11.034-4.94,11.034-11.034c0-6.094-4.94-11.034-11.034-11.034
		H313.47C307.502,260.554,302.198,267.907,297.811,275.862z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M282.817,328.828h52.631c6.094,0,11.034-4.94,11.034-11.034c0-6.094-4.94-11.034-11.034-11.034
		h-49.482C284.266,313.897,283.219,321.268,282.817,328.828z"
      />
    </g>
    <circle style="fill:#B4E66E;" cx="406.069" cy="335.448" r="105.931" />
    <path
      style="fill:#A0D755;"
      d="M459.034,420.193c-58.504,0-105.931-47.427-105.931-105.931c0-34.347,16.419-64.785,41.757-84.14
	c-53.231,5.601-94.722,50.61-94.722,105.326c0,58.504,47.427,105.931,105.931,105.931c24.158,0,46.353-8.178,64.174-21.791
	C466.558,419.977,462.822,420.193,459.034,420.193z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M388.414,390.621c-2.828,0-5.646-1.078-7.801-3.233l-44.138-44.138c-4.31-4.31-4.31-11.293,0-15.604
	c4.31-4.311,11.293-4.31,15.604,0l36.337,36.337l71.647-71.647c4.31-4.31,11.293-4.31,15.604,0c4.311,4.31,4.31,11.293,0,15.604
	l-79.448,79.448C394.06,389.543,391.242,390.621,388.414,390.621z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
